.mainContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgb(50, 50, 50);
    padding: 0;
    margin: 0;

    display: grid;
    align-content: center;
    justify-content: center;

    grid-template-rows: 50px 40px;
}

.contentTitle {
    color: rgb(169, 169, 169);
}

.codeInput {
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 20px;
    outline: none;
    background-color: rgb(60, 60, 60);
    text-indent: 10px;
    color: #fff;
}