
.questionContainer {
    width: 100vw;
    height: calc(100vh - var(--container-height-m));

    background-color: rgb(60, 60, 60);

    display: grid;
    align-content: center;
    justify-content: center;

    grid-template-rows: 80px 80px;
}

.questionItem > button {
    width: 50vw;
    height: 3rem;
    border-radius: 50px;
    border: none;
    outline: none;
    background-color: rgb(158, 158, 158);
}

.questionItem > button:hover {
    background-color: #f9f9f9;
}

.interviewContainer {
    width: 100vw;
    background-color: var(--interview-bg-color);
    height: calc(100vh - var(--container-height-m));

}

.qnaArea {
    height: calc(100% - 60px);

    text-align: center;

    display: grid;
    justify-content: center;
    align-items: center;

    grid-template-rows: 0.7fr 100px 200px;

}

.pageControllArea {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    position: fixed;
    bottom: 30px;
}
.pageControllArea > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageControllArea button {
    height: 40px;
    width: 100px;
    border: none;
    background-color: #d5d5d5;
    border-radius: 50px;
}


.interviewWrap {
    padding: 0 30px;
}
.interviewWrap > pre {
    height: 200px;
    overflow: auto;

    white-space: break-spaces;
}

.interviewWrap > .interviewQuestion {
    height: 100px;

}

.interviewWrap > div {
    width: 100%;
    text-align: center;
}

/* area > wrap > box */