:root {
    --interview-bg-color: #dfdfdf;
    --container-height-m: 0px;
}


* {
    margin: 0;
    padding: 0;
}

button {
    cursor: pointer;
}